import Cookies from 'js-cookie'
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useMeUpdateMutation } from '../hooks/coreApi/useMeUpdateMutation'
import useNativeApp from '../hooks/useNativeApp'
import { UserConfigFontSize } from '../models/user'
import { UserResponse } from '../openapi'
import { getCookieOptions } from '../utils/auth'
import { useAuthContext } from './AuthContext'

type Props = {
  children: React.ReactNode
}

type UserPersistConfigContextType = {
  userPersistConfig: UserPersistConfig
  setConfigValue(key: string, value: any): void
}

type UserPersistConfig = Required<
  Pick<
    UserResponse,
    | 'cnfFontSize'
    | 'cnfConfirmAtLeavingMeeting'
    | 'cnfEnableVideoAtStartOfMeeting'
    | 'cnfEnableMicAtStartOfMeeting'
    | 'cnfAllowInviteFromFriends'
    | 'cnfSendNotifTaxiReservationFixed'
    | 'cnfSendNotifTaxiReservationRejected'
    | 'cnfSendNotifTaxiReservationReminder'
    | 'cnfSendNotifTrashDay'
    | 'cnfSendNotifBousai'
    | 'cnfSendNotifKairan'
    | 'cnfSendNotifDekigoto'
  >
>

const userPersistConfigDefaultValues: UserPersistConfig = {
  cnfFontSize: UserConfigFontSize.MEDIUM,
  cnfConfirmAtLeavingMeeting: false,
  cnfEnableVideoAtStartOfMeeting: false,
  cnfEnableMicAtStartOfMeeting: false,
  cnfAllowInviteFromFriends: false,
  cnfSendNotifTaxiReservationFixed: false,
  cnfSendNotifTaxiReservationRejected: false,
  cnfSendNotifTaxiReservationReminder: false,
  cnfSendNotifTrashDay: false,
  cnfSendNotifBousai: false,
  cnfSendNotifKairan: false,
  cnfSendNotifDekigoto: false,
}

const UserPersistConfigContext = createContext<UserPersistConfigContextType>({
  userPersistConfig: userPersistConfigDefaultValues,
  setConfigValue: () => {},
})

export const useUserPersistConfigContext = () =>
  React.useContext(UserPersistConfigContext)

// 全体フォントサイズ変更Effect
const useEffectFontSize = (userPersistConfig: UserPersistConfig) => {
  useEffect(() => {
    const html = document.querySelector('html')
    if (!html) {
      return
    }

    // 設定に合わせてHTMLのDOMを書き換え
    switch (userPersistConfig.cnfFontSize) {
      case UserConfigFontSize.SMALL:
        html.style.fontSize = '12px'
        break
      case UserConfigFontSize.MEDIUM:
        html.style.fontSize = '16px'
        break
      case UserConfigFontSize.LARGE:
        html.style.fontSize = '20px'
        break
      default:
    }
  }, [userPersistConfig.cnfFontSize])
}

export const UserPersistConfigProvider: React.FC<Props> = ({ children }) => {
  const nativeApp = useNativeApp()
  const [userPersistConfig, setUserPersistConfig] = useState<UserPersistConfig>(
    userPersistConfigDefaultValues,
  )
  const mutation = useMeUpdateMutation()

  const { isAuthorized, me } = useAuthContext()

  useEffectFontSize(userPersistConfig)

  useEffect(() => {
    if (isAuthorized && me) {
      // サーバー値で初期化
      setUserPersistConfig({
        cnfFontSize: me.cnfFontSize || 1,
        cnfConfirmAtLeavingMeeting: me.cnfConfirmAtLeavingMeeting || false,
        cnfEnableVideoAtStartOfMeeting:
          me.cnfEnableVideoAtStartOfMeeting || false,
        cnfEnableMicAtStartOfMeeting: me.cnfEnableMicAtStartOfMeeting || false,
        cnfAllowInviteFromFriends: me.cnfAllowInviteFromFriends || false,
        cnfSendNotifTaxiReservationFixed:
          me.cnfSendNotifTaxiReservationFixed || false,
        cnfSendNotifTaxiReservationRejected:
          me.cnfSendNotifTaxiReservationRejected || false,
        cnfSendNotifTaxiReservationReminder:
          me.cnfSendNotifTaxiReservationReminder || false,
        cnfSendNotifTrashDay: me.cnfSendNotifTrashDay || false,
        cnfSendNotifBousai: me.cnfSendNotifBousai || false,
        cnfSendNotifKairan: me.cnfSendNotifKairan || false,
        cnfSendNotifDekigoto: me.cnfSendNotifDekigoto || false,
      })
    }
  }, [setUserPersistConfig, isAuthorized, me])

  const setConfigValue = useCallback(
    (key: string, value: any) => {
      if (Object.keys(userPersistConfigDefaultValues).includes(key) === false) {
        throw new Error(`key: ${key} is not found in userPersistConfig`)
      }

      setUserPersistConfig({ ...userPersistConfig, [key]: value })
      mutation.mutate({
        meUpdateRequest: {
          [key]: value,
        },
      })
    },
    [mutation, userPersistConfig],
  )

  useEffect(() => {
    Cookies.set('userConfig', JSON.stringify(userPersistConfig), {
      ...getCookieOptions(),
    })
    setTimeout(() => {
      nativeApp.didLogin()
    }, 1000)
  }, [nativeApp, userPersistConfig])

  const value = useMemo(
    () => ({
      userPersistConfig,
      setConfigValue,
    }),
    [userPersistConfig, setConfigValue],
  )

  return (
    <UserPersistConfigContext.Provider value={value}>
      {children}
    </UserPersistConfigContext.Provider>
  )
}
