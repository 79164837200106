import axios, { AxiosError } from 'axios'
import queryString from 'query-string'
import {
  NotificationApi,
  Configuration,
  MeApi,
  ContentApi,
  UserApi,
  AreaApi,
  PlaceApi,
  TenantApi,
  FriendApi,
  RoomApi,
  RoomConnectionApi,
  TaxiAchievementApi,
  TaxiReservationApi,
  TaxiCarApi,
  TaxiCompanyApi,
  TaxiLandmarkApi,
  TrashCalendarApi,
  OauthApi,
  TaxiDailyScheduleApi,
  RoomMeetingApi,
} from '../../openapi'
import { getIdToken } from '../../utils/auth'

export const appApiAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
  headers: {
    'X-Tenant-Id': 1,
  },
  paramsSerializer: (params) =>
    queryString.stringify(params, { arrayFormat: 'bracket' }),
})

appApiAxiosInstance.interceptors.request.use((config) => {
  const accessToken = getIdToken()
  // サーバー側で無効なトークンはverifyエラー(401)となるため
  // 認証ヘッダーを付与しないパスを指定する
  const authIgnorePaths = ['/api/users/token']
  const { pathname } = new URL(config.url || '', config.baseURL)
  const isAuthIgnore = authIgnorePaths.includes(pathname)

  if (accessToken && !isAuthIgnore) {
    if (config && config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
  }
  return config
})

export const openApiConfig = new Configuration({
  basePath: `${process.env.REACT_APP_API_BASE_URL}/api`,
})

// 必要なAPIクラス(OPEN APIのタグ毎に分割される）はここに列挙して初期化する
const appApiClient = {
  meApi: new MeApi(openApiConfig, '', appApiAxiosInstance),
  oauthApi: new OauthApi(openApiConfig, '', appApiAxiosInstance),
  contentApi: new ContentApi(openApiConfig, '', appApiAxiosInstance),
  userApi: new UserApi(openApiConfig, '', appApiAxiosInstance),
  tenantApi: new TenantApi(openApiConfig, '', appApiAxiosInstance),
  areaApi: new AreaApi(openApiConfig, '', appApiAxiosInstance),
  placeApi: new PlaceApi(openApiConfig, '', appApiAxiosInstance),
  friendApi: new FriendApi(openApiConfig, '', appApiAxiosInstance),
  roomApi: new RoomApi(openApiConfig, '', appApiAxiosInstance),
  roomMeetingApi: new RoomMeetingApi(openApiConfig, '', appApiAxiosInstance),
  roomConnectionApi: new RoomConnectionApi(
    openApiConfig,
    '',
    appApiAxiosInstance,
  ),
  taxiReservationApi: new TaxiReservationApi(
    openApiConfig,
    '',
    appApiAxiosInstance,
  ),
  taxiAchievementApi: new TaxiAchievementApi(
    openApiConfig,
    '',
    appApiAxiosInstance,
  ),
  taxiCarApi: new TaxiCarApi(openApiConfig, '', appApiAxiosInstance),
  taxiCompanyApi: new TaxiCompanyApi(openApiConfig, '', appApiAxiosInstance),
  taxiLandmarkApi: new TaxiLandmarkApi(openApiConfig, '', appApiAxiosInstance),
  taxiDailyScheduleApi: new TaxiDailyScheduleApi(
    openApiConfig,
    '',
    appApiAxiosInstance,
  ),
  trashCalendarApi: new TrashCalendarApi(
    openApiConfig,
    '',
    appApiAxiosInstance,
  ),
  notificationApi: new NotificationApi(openApiConfig, '', appApiAxiosInstance),
}

type ErrorResponse = {
  errors?: { [key: string]: string }
}

export const parseErrorMessages = (err: AxiosError): string[] => {
  // API側で想定しているバリデーションエラーの場合
  // TODO エラーのフォーマット統一
  const axiosError = err as AxiosError<ErrorResponse>
  const errors =
    axiosError?.response?.data?.errors || axiosError?.response?.data || []

  let res = []

  if (Object.values(errors).length > 0) {
    res = Object.values(errors)
    return res
  }

  // バリデーション以外のエラー
  let message = ''
  switch (axiosError.code) {
    case '401':
      message = '認証エラーです'
      break
    case '403':
      message = '入力値に誤りがあります'
      break
    case '404':
      message = '存在しないURLです'
      break
    case '500':
    default:
      message = 'サーバーエラーが発生しました'
      break
  }
  res = [message]

  return res
}

export default appApiClient
