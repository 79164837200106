import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AppContentBasic } from '../../components/common/AppContentBasic'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import { AppPDFView } from '../../components/common/AppPDFView'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import { useContentReadMutation } from '../../hooks/coreApi/useContentReadMutation'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getDateFormat } from '../../utils/date'

const KairanDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  useHeaderConfig({
    title: 'お知らせ',
  })
  const contentReadMutation = useContentReadMutation()
  const contentQuery = useContentListQuery('kairan', {
    id: Number(id),
    withFiles: true,
  })
  const content = contentQuery?.data?.pages[0].contents[0]

  useEffect(() => {
    if (id) {
      contentReadMutation.mutate({
        contentReadRequest: {
          contentIds: [Number(id)],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (contentQuery.isLoading) {
    return <AppLoading />
  }

  if (!content) {
    return <AppErrorResult message="コンテンツが見つかりませんでした" />
  }

  // TODO detailTypeで判定 PDFファイルの場合 PDFファイルの特定
  const pdfFile =
    content.files && content.files.find((f) => f.type === 'content_kairan_pdf')

  // PDFの場合
  if (pdfFile) {
    return <AppPDFView fileUrl={pdfFile.cloudFrontUrl} />
  }

  // 通常記事の場合
  return (
    <AppContentBasic
      date={getDateFormat(content.releaseAt)}
      title={content.titleJa || ''}
      body={content.bodyJa || ''}
      thumbnailImageUrl={
        content.files?.find((f) => f.type === 'content_image')?.cloudFrontUrl ||
        undefined
      }
      isShowLike
      isLiked={content?.isLiked || false}
      likeCount={content?.likeCount || 0}
      tags={['イベント']}
      contentId={content.id}
    />
  )
}

export default KairanDetail
