import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { CONTENT_DEFAULT_PAGE_SIZE } from '../../global-config'
import {
  ContentApiContentListRequest,
  ContentListResponse,
} from '../../openapi/api'
import { dateToFormatRFC3339 } from '../../utils/date'
import appApiClient from './appApiClient'

type ContentType = 'bousai' | 'kairan' | 'musen' | 'dekigoto' | 'kinkyu'

// TODO 一時的に追加 API側で対応
type ListOptions = {
  sortField?: 'createdAt' | 'releaseAt'
  sortDirection?: 'asc' | 'desc'
  placeId?: number
}

export const QUERY_KEY_CONTENT_LIST = 'contentList'

export const useContentListQuery = (
  type: ContentType | ContentType[],
  req: ContentApiContentListRequest & ListOptions,
) => {
  const query = useInfiniteQuery({
    queryKey: [QUERY_KEY_CONTENT_LIST, type, req],
    queryFn: async ({ pageParam = 1 }) => {
      const pages = await pageParam
      if (pages === undefined) {
        return
      }

      const baseParams: ContentApiContentListRequest = {
        pageSize: CONTENT_DEFAULT_PAGE_SIZE, // TODO 一時的に対応 各コンテンツ実装箇所で対応
        ...req,
        page: pages,
        withIsRead: true,
        withLikeCount: true,
        withIsLiked: true,
        withContentPlacesPlace: true,
        endDate:
          req.endDate && dayjs(req.endDate).isBefore(dayjs())
            ? req.endDate
            : dateToFormatRFC3339(dayjs()), // デフォルトで現在時刻以降のコンテンツは取得しない
        withContentTags: true,
      }

      let params: ContentApiContentListRequest = {}

      if (Array.isArray(type)) {
        params = {
          ...baseParams,
          types: type,
        }
      } else {
        params = {
          ...baseParams,
          type,
        }
      }

      const res = await appApiClient.contentApi.contentList(params)
      // eslint-disable-next-line consistent-return
      // return res.data

      // TODO ソート 一時的に追加 API側で対応
      if (!req.sortField) {
        req.sortField = 'releaseAt'
      }

      if (!req.sortDirection) {
        req.sortDirection = 'desc'
      }
      if (req.sortField && req.sortDirection) {
        res.data.contents = orderBy(
          res.data.contents,
          req.sortField,
          req.sortDirection,
        )
      }

      // eslint-disable-next-line consistent-return
      return res.data
    },
    getNextPageParam: (lastPage, allPages) => {
      if (
        lastPage?.count === undefined ||
        req.page === undefined ||
        req.pageSize === undefined
      ) {
        return undefined
      }

      // 総件数よりも表示中の件数のほうが大きくなった場合、undefined
      if (lastPage.count <= allPages.length * req.pageSize) {
        return undefined
      }
      return allPages.length + 1
    },
    // cacheTime: 0, // react-queryのcacheがデフォルト5分になっているため、OFFにする（ログイン情報などのキャッシュを防ぐため）
  })

  return query as UseInfiniteQueryResult<ContentListResponse, AxiosError>
}
