import { SearchIcon } from '@chakra-ui/icons'
import {
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  Select,
  Box,
} from '@chakra-ui/react'
import _ from 'lodash'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom'
import { usePlaceListQuery } from '../../../hooks/coreApi/usePlaceListQuery'
import { p2r } from '../../../utils/font'

type SearchParamsType = {
  year: string
  placeId: string
  tag: string
  search: string
}

type Props = {
  isShowArea?: boolean
  isShowTag?: boolean
  isShowYear?: boolean
}

const AppSearchHeader: React.FC<Props> = ({
  isShowArea = false,
  isShowTag = false,
  isShowYear = false,
}) => {
  const { search: queryStringSearch, pathname } = useLocation()
  const navigate = useNavigate()
  const [path, setPath] = useState<string>('')
  const [searchParams, setSearchParams] = useState<SearchParamsType>({
    year: '',
    placeId: '',
    tag: '',
    search: '',
  })

  const placeQuery = usePlaceListQuery({})

  const formatParams = (params: SearchParamsType) => {
    const fmtParams: { [key: string]: string } = {}
    const { year, placeId, search } = params
    if (year !== '') fmtParams.year = year
    if (placeId !== '') fmtParams.placeId = placeId
    if (search !== '') fmtParams.search = search
    return fmtParams
  }

  const inputLabel = (currentPath: string) => {
    // TASK pathで判定をしてplaceholderのテキストを変更する
    if (currentPath === '/') {
      return 'かみやまchを探す'
    }
    if (currentPath.indexOf('/musen') !== -1) {
      return '防災無線を探す'
    }
    if (currentPath.indexOf('/kairan') !== -1) {
      return 'お知らせを探す'
    }

    if (currentPath.indexOf('/dekigoto') !== -1) {
      return 'かみやまchを探す'
    }

    if (currentPath.indexOf('/bousai') !== -1) {
      return '防災情報を探す'
    }

    return '探す'
  }

  const resetParams = () => {
    setSearchParams({
      ...searchParams,
      year: '',
      placeId: '',
      search: '',
    })
  }

  useEffect(() => {
    const query = queryString.parse(queryStringSearch)
    if (!_.isEmpty(query)) {
      setSearchParams({
        ...searchParams,
        year: query.year ? query.year.toString() : '',
        placeId: query.placeId ? query.placeId.toString() : '',
        search: query.search ? query.search.toString() : '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (path === '' || path === pathname) {
      const params = formatParams(searchParams)
      if (_.isEmpty(params)) return
      navigate({
        pathname,
        search: `?${createSearchParams(params)}`,
      })
    } else {
      resetParams()
    }
    setPath(pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, navigate, searchParams])

  return (
    <Box
      m="auto"
      bg={{ base: 'grand.white', md: 'theme.background' }}
      p={{ base: '8px 16px', md: '15px' }}
    >
      <Flex
        as="header"
        justifyContent="space-between"
        maxW="1194px"
        m="auto"
        direction={{ base: 'column', md: 'row' }}
        rowGap={{ base: '8px', md: '0px' }}
      >
        <Flex columnGap={{ base: 'unset', md: '16px' }}>
          <InputGroup maxW={{ base: '100%', md: '314px' }}>
            <InputLeftElement cursor="pointer">
              <SearchIcon color="#DFE1E4" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder={inputLabel(pathname)}
              _placeholder={{ color: '#DFE1E4' }}
              bg="#fff"
              borderRadius="8px"
              borderColor="grand.grayLight"
              onBlur={(e) => {
                setSearchParams({
                  ...searchParams,
                  search: e.target.value,
                })
              }}
            />
          </InputGroup>
        </Flex>
        <Flex columnGap={{ base: '8px', md: '16px' }}>
          {isShowTag && (
            <Select
              w={{ base: '120px', md: '140px' }}
              h={{ base: '32px', md: '40px' }}
              placeholder="カテゴリを選択"
              fontWeight="700"
              bg="theme.primary"
              color="#fff"
              rounded="8px"
              fontSize={{ base: p2r(12), md: p2r(14) }}
              onChange={(e) =>
                setSearchParams({ ...searchParams, tag: e.target.value })
              }
            />
          )}
          {isShowArea && (
            <Select
              w={{ base: '120px', md: '140px' }}
              h={{ base: '32px', md: '40px' }}
              placeholder="エリアを選択"
              fontWeight="700"
              bg="theme.primary"
              color="#fff"
              rounded="8px"
              fontSize={{ base: p2r(12), md: p2r(14) }}
              onChange={(e) =>
                setSearchParams({ ...searchParams, placeId: e.target.value })
              }
            >
              {placeQuery?.data?.places.map((place) => (
                <option key={place.id} value={place.id}>
                  {place.name}
                </option>
              ))}
            </Select>
          )}

          {isShowYear && (
            <Select
              maxW="90px"
              h={{ base: '32px', md: '40px' }}
              fontWeight="700"
              bg="theme.primary"
              color="#fff"
              fontSize={{ base: p2r(12), md: p2r(14) }}
              order={{ base: '-1', md: 'unset' }}
              rounded="8px"
              defaultValue={new Date().getFullYear().toString()}
              onChange={(e) =>
                setSearchParams({ ...searchParams, year: e.target.value })
              }
            >
              {/* 2022 〜 現在年 */}
              {_.range(2022, new Date().getFullYear() + 1).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default AppSearchHeader
