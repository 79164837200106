import { RoomMeetingResponse } from '../openapi'

export const getRoomMeetingStatusText = (roomMeeting: RoomMeetingResponse) => {
  switch (roomMeeting.compositionStatus) {
    case 'completed':
      return 'ファイル作成済'
    case 'processing':
      return 'ファイル作成中'
    case 'not_started':
    default:
      return 'ファイル未作成'
  }
}
