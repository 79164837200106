import { useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppContentModal from '../../components/common/AppContentModal'
import { AppContentMovie } from '../../components/common/AppContentMovie'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import { useContentReadMutation } from '../../hooks/coreApi/useContentReadMutation'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getDateFormat } from '../../utils/date'

const DekigotoDetailContent: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const contentQuery = useContentListQuery('dekigoto', { id: Number(id) })
  const content = contentQuery?.data?.pages[0].contents[0]
  const contentReadMutation = useContentReadMutation()

  useEffect(() => {
    if (id) {
      contentReadMutation.mutate({
        contentReadRequest: {
          contentIds: [Number(id)],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (contentQuery.isLoading) {
    return <AppLoading />
  }

  if (!content) {
    return <AppErrorResult message="コンテンツが見つかりませんでした" />
  }

  return (
    <AppContentMovie
      moviePath={content?.dekigotoVideoUrl || ''}
      date={getDateFormat(content?.releaseAt)}
      title={content?.titleJa || ''}
      body={content?.bodyJa || ''}
      isShowLike
      isLiked={content?.isLiked}
      isReaded={content?.isRead}
      likeCount={content?.likeCount}
      playCount={content?.likeCount}
      tags={['イベント']}
      contentId={content.id}
    />
  )
}

const DekigotoDetail: React.FC = () => {
  useHeaderConfig({
    title: 'かみやまch',
  })

  // モーダル
  const contentMenuDisclosure = useDisclosure()

  const navigate = useNavigate()

  useEffect(() => {
    contentMenuDisclosure.onOpen()
  }, [contentMenuDisclosure])

  return (
    <AppContentModal
      isOpen={contentMenuDisclosure.isOpen}
      onClose={() => {
        navigate(-1)
        contentMenuDisclosure.onClose()
      }}
    >
      <DekigotoDetailContent />
    </AppContentModal>
  )
}

export default DekigotoDetail
